import React, { useEffect, useRef } from "react"
import useLocalStorageState from 'use-local-storage-state'
import { Navigation } from "../components/03Organisms/Navigation"
import { MenuItems } from "../components/03Organisms/NavigationMenuItems"
import SectionAll from "../components/04Pages/SectionAll"
import Layout from "../components/layout"
import { checkIsIOS } from "../components/_helpers"
import useWindowSize from "../hooks/useWindowSize"
import { useIsMount } from "./../hooks/useIsMount"


const IndexPage = props => {
  // gsap.registerPlugin(ScrollTrigger);

  const parallaxRef = useRef()
  const size = useWindowSize()
  const isMount = useIsMount()

  const isIOS = checkIsIOS()
  // const isMobile = size.width < 641
  const [mobileMenu, setMobileMenu] = useLocalStorageState(false)


  let y = 0
  // let style = { "--offset": y };

  

  useEffect(() => {
    let locomotiveScroll = window.scroll
    locomotiveScroll.update();
    
    let scroll = {cache: 0, current: 0};

    locomotiveScroll.on("scroll", func => {    
      scroll.current = func.scroll.y;
      scroll.cache = scroll.current;
    });
  })

  

  // let scrollT = 0

  // console.log("mobile menu", mobileMenu)
  // console.log("mobile menu", mobileMenu)
  // console.log("window y", scrollY)
  // console.log("y", y)
  // console.log("menu open", mobileMenu)

  return (
    <Layout>
      <div
        ref={parallaxRef}
        data-scroll-container
        className="scroll-container"
        id="yah"
      >
        {/* <div className="dots dots1"></div> */}
        {/* <div className="dots dots2"></div> */}

        <span
          className={`mobile-menu-toggle ${mobileMenu ? "open" : ""}`}
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          {mobileMenu ? (
            <b
              className="las la-times"
              onClick={() => setMobileMenu(!mobileMenu)}
            >✕</b>
            // <i
            //   className="las la-times"
            //   onClick={() => setMobileMenu(!mobileMenu)}
            // ></i>
          ) : (
            "Menu"
          )}
        </span>

        <div className={`mobile-menu show`}>
          <div className={`navigation ${mobileMenu ? "expanded" : ""}`}>
            <div
              key="tilty-mobile"
              className={`menu-items ${mobileMenu ? "expanded" : ""}`}
            >
              <MenuItems />
            </div>
          </div>
        </div>

        <Navigation mobileMenu={mobileMenu} />
        <SectionAll mobileMenu={mobileMenu} size={size} />
      </div>
    </Layout>
  )
}

export default IndexPage
